import React from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';


const Testimonials = () => {
    const testimonialsData = [
        {
            name: 'Ricky Limbu, Nagaland, India',
            message: `Can't wait for the App to launch.`,
            stars: 5,
        },
        {
            name: 'Wabang Longkumer, Nagaland, India',
            message: `A very noble endeavour, eagerly waiting for it's launch.`,
            stars: 5,
        },
        {
            name: 'R John Zeliang, Nagaland, India',
            message: `Giving does not only precede receiving; it is the reason for it!No one has ever become poor from giving....`,
            stars: 5,
        },
        {
            name: 'Neitho sekhose, Nagaland, India',
            message: `A very exhilarating advantage awaits us. Can't hold back to set afloat`,
            stars: 5,
        },
        {
            name: 'M. M. L Illinois, USA',
            message: `What a great initiative to connect people, strengthen communities and simply, extend kindness. Keep it up!`,
            stars: 5,
        },
        {
            name: 'Mongzeung Mpom, Nagaland, India',
            message: `If you see anyone in need and you have what they need, take it as an opportunity to bless them`,
            stars: 5,
        },
        {
            name: 'Nise Meruno, Nagaland, India',
            message: `This is such a wonderful initiative created by the young genius Zaiyigum. I am so proud of him.
            This is going to help so many people in so many ways. An easy-to-use app all set to enrich someone's life. Sending my best wishes.
            God bless!!`,
            stars: 5,
        },
        {
            name: 'Kehodi Yoho, Nagaland, India',
            message: `Amazing app it is going to be! Need of the hour!`,
            stars: 5,
        },
        {
            name: 'Chef Joel Basumatari, Nagaland, India',
            message: `Giving is the most effective way of receiving blessing!`,
            stars: 5,
        },
        {
            name: 'Raksha S Reddy, Netherlands',
            message: `"Give the Needy" is a thought-provoking app with a noble mission, featuring a user-friendly interface and seamless donation process, making it a fantastic platform for making a meaningful difference in the world. Highly recommended!`,
            stars: 5,
        },
        {
            name: 'Neikuolie Khezhie, Nagaland, India',
            message: `May this app be a spark that ignites Love across all communities. God bless team Zeliang Codetech!`,
            stars: 5,
        },
        // Add more testimonials as needed
    ];

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
    };

    const renderStars = (numberOfStars) => {
        const filledStars = '★'.repeat(numberOfStars);
        const emptyStars = '☆'.repeat(5 - numberOfStars);

        return filledStars + emptyStars;
    };

    return (
        <div className=' p-10 text-center mb-10'>
            <h3 className='text-[var(--primaryColor)] sm:text-[2rem] font-semibold tracking-widest sm:leading-[3rem]'>What people are saying about <span className='testo_title'>GiveTheNeedy</span></h3>
            <div className='w-full m-auto rounded-lg  mt-10 p-8 md:p-20 leading-2 md:leading-10 bg-[var(--primaryColor)]'>
                <Slider {...settings}>
                    {testimonialsData.map((testimonial, index) => (
                        <div key={index} className={`text-white mb-8`}>
                            <p>{testimonial.message}</p>
                            <p>{renderStars(testimonial.stars)}</p>
                            <p>- {testimonial.name}</p>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
};

export default Testimonials;
