import React from 'react'
import MyPost from './MyPost'
import logo from '../Assets/Images/logo.png'
import NavBarSide from './NavBarSide'

const Profile = () => {
    return (
        <MyPost />

    )
}

export default Profile